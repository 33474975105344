import { interval, Subscription } from 'rxjs';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { DeviceService, SendCodeService } from '@wlp/ui-bs-device-validation';
import { UiCSendCodeComponent } from '@wlp/ui-c-send-code';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-device',
  templateUrl: './device.page.html',
  styleUrls: ['./device.page.scss'],
})
export class DevicePage implements OnInit {
  brand: string;
  sub: Subscription;
  errorMessages: any;
  isDisabled: boolean;
  formDevice: FormGroup;
  formSendCode: FormGroup;
  progressbarValue: number;
  showGroupButtons: boolean;
  navigationBackground: string;
  @ViewChild('cd', { static: false }) public sendCode: UiCSendCodeComponent;
  constructor(
    private navCtrl: NavController,
    public deviceService: DeviceService,
    public toastController: ToastController,
    private sendCodeService: SendCodeService,
    private alertController: AlertController,
    private layoutConfigService: LayoutConfigService,
    private storage: Storage,
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }
  ngOnInit(): void {
    this.isDisabled = true;
    this.progressbarValue = 1;
    this.formDevice = this.deviceService.getForm();
    this.formSendCode = this.sendCodeService.getForm();
    this.errorMessages = this.deviceService.getErrorFormMessagen();
    this.errorMessages = this.sendCodeService.getErrorFormMessage();
    this.deviceService.updateForm();
    this.showGroupButtons = false;
  }

  ionViewWillEnter() {
    this.formSendCode.disable();
    this.isDisabled = true;
  }

  startProgress() {
    const timer$ = interval(1000);
    this.sub = timer$.subscribe((sec) => {
      this.progressbarValue = 1 - (sec * 0.67) / 1200;
      if (this.progressbarValue === 0) {
        this.sub.unsubscribe();
      }
    });
  }

  restratProgress() {
    this.sub.unsubscribe();
    this.startProgress();
  }

  resendCode(data): void {
    this.restratProgress();
    this.sendCodeService.resend();
    this.sendCode.countdown.restart();
    this.sendCode.countdown.begin();
  }

  continueCountDown(event) {
    if (event.action === 'stop') {
      this.sendCode.countdown.restart();
    }
  }

  continueDevice() {
    this.storage.set('phoneValue', this.formDevice.value.phone)
    this.deviceService
      .createDevice()
      .then((res) => null)
      .catch((err) => this.deviceService.getErrorDevice(err));
    this.startProgress();
    this.sendCode.countdown.begin();
    this.formDevice.disable();
    this.formSendCode.enable();
    this.isDisabled = false;
    this.showGroupButtons = true;
  }

  continueSendCode(): void {
    this.sendCodeService.sendCode().then(
      (res) => {
        if (res.valid) {
          this.navCtrl.navigateRoot('birth-date');
          return;
        } else {
          this.presentAlert();
        }
      },
      (err) => this.sendCodeService.getErrorSendCode(err)
    );
  }

  resetFlow() {
    this.progressbarValue = 1;
    this.formDevice.enable();
    this.formSendCode.disable();
    this.isDisabled = true;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sendCode.countdown.stop();
  }

  goBackDevice() {
    this.resetFlow();
    this.showGroupButtons = false;
  }

  goBack() {
    this.resetFlow();
    this.navCtrl.navigateForward('personal-address');
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'O código digitado não é válido!',
      duration: 2000,
    });
    toast.present();
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Atenção!',
      message: 'O código digitado não é válido. Verifique o código recebido e tente novamente.',
      buttons: ['OK'],
    });
    await alert.present();
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
