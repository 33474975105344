import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StepServiceResolver } from '../../../functions/resolver/step-service-resolver';
import { AccountTypePage } from './account-type/account-type.page';
import { ChooseDocumentPage } from './choose-document/choose-document.page';
import { ComercialAgentPage } from './comercial-agent/comercial-agent.page';
import { CompanyAddressPage } from './company-address/company-address.page';
import { CompanyDataPage } from './company-data/company-data.page';
import { CompanyDocumentsPage } from './company-documents/company-documents.page';
import { ContractPage } from './contract/contract.page';
import { CreateAccountPage } from './create-account/create-account.page';
import { DocumentTypePage } from './document-type/document-type.page';
import { DocumentsLiberalPage } from './documents-liberal/documents-liberal.page';
import { DocumentsPartnerPage } from './documents-partner/documents-partner.page.html.page';
import { PartnersPage } from './partners/partners.page';
import { PersonalAddressPage } from './personal-address/personal-address.page';
import { PersonalDataPage } from './personal-data/personal-data.page';
import { PrivacyPolicyPage } from './privacy-policy/privacy-policy.page';
import { ProfessionalTypePage } from './professional-type/professional-type.page';
import { UploadDocumentsPage } from './upload-documents/upload-documents.page';
import { BirthDatePage } from './birth-date/birth-date.page';
import { QuizAnswerPage } from './quiz-answer/quiz-answer.page';
import { OcrScannerDataPage } from './ocr-scanner-data/ocr-scanner-data.page';

const routes: Routes = [
  {
    path: 'account-type',
    component: AccountTypePage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'choose-document',
    component: ChooseDocumentPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'birth-date',
    component: BirthDatePage,
    resolve: { route: StepServiceResolver }
  },
  {
    path: 'quiz-answer',
    component: QuizAnswerPage,
    resolve: { route: StepServiceResolver }
},
{
  path: 'ocr-scanner',
  component: OcrScannerDataPage,
  resolve: { route: StepServiceResolver }
},
  {
    path: 'comercial-agent',
    component: ComercialAgentPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'company-address',
    component: CompanyAddressPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'company-data',
    component: CompanyDataPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'company-documents',
    component: CompanyDocumentsPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'contract',
    component: ContractPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'create-account',
    component: CreateAccountPage,
  },
  {
    path: 'document-type',
    component: DocumentTypePage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'documents-partner',
    component: DocumentsPartnerPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'partners',
    component: PartnersPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'personal-address',
    component: PersonalAddressPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'personal-data',
    component: PersonalDataPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'professional-type',
    component: ProfessionalTypePage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'upload-documents',
    component: UploadDocumentsPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPage,
    resolve: { route: StepServiceResolver },
  },
  {
    path: 'documents-liberal',
    component: DocumentsLiberalPage,
    resolve: { route: StepServiceResolver },
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignUpRoutingModule {}
